// create media query to jump email to next line.
.form-container {
  width: 100%;
  max-width: 40rem;
  padding: 1rem;
  margin: 0rem auto 3rem;
  button {
    background: #4ca1af;
    width: 100%;
    height: 3rem;
    padding: 0.8rem 0rem;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    font-family: "Roboto", sans-serif;
    font-size: 1.1rem;
    letter-spacing: 0.08rem;
    &:hover {
      transition: all 0.5s ease;
      background: #37b95e;
    }
  }
  section {
    padding-top: 0.7rem; //between each of the 3 sections
  }

  form {
    width: 100%;
    padding-top: 0.3rem;
    span {
      color: red;
    }
  }
  .contact-me {
    text-align: center;
    h1 {
      letter-spacing: 0.06rem;
    }
    span {
      font-size: 0.8rem;
      font-style: italic;
      p {
        color: red;
      }
      letter-spacing: 0.1rem;
      display: flex;
      justify-content: center;
      color: #575757;
    }
  }
  textarea {
    display: flex;
    height: 7rem;
    max-width: 100%;
    max-height: 15rem;
    min-width: 50%;
    min-height: 4rem;
    margin-bottom: 0.7rem;
  }
  input,
  textarea {
    padding: 1rem 0.3rem;
    width: 100%;
    border: 0.08rem solid rgb(221, 221, 221);
    border-radius: 0.3rem;
    outline: none;
    transition: all 0.2s ease;
    font-family: "Canterall", sans-serif;
    &:focus {
      border-color: #4ca1af;
      box-shadow: inset 0 0.05rem 0.05rem rbga(0, 0, 0, 0.0125),
        0 0 0.5rem rbga(76, 161, 175, 0.5);
    }
  }
  .name,
  .email {
    width: 48%;
  }
  .first-row {
    display: flex;
    justify-content: space-between;
  }
  label {
    display: inline-block;
    padding-bottom: 0.4rem;
  }
}

// the first-row needs to jump down at 460
// @media (max-width: 640px) {
//     .name .email {
//         asdfa
//     }
// }
