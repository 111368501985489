.about-the-author {
  margin-bottom: 1rem;

  @media (min-width: 700px) {
    display: flex;
    justify-content: space-between;
    padding: 2rem 5%;
    align-items: flex-start;
  }

  @media (min-width: 1200px) {
    align-items: center;
  }

  h1 {
    margin-top: 1rem;
    padding-bottom: 1rem;
    @media (min-width: 700px) {
      margin-top: 0;
    }
  }

  .author-image {
    @media (min-width: 700px) {
      margin-right: 3rem;
      flex: 1 0 40%;
      margin-left: 10%;
      max-width: 360px;
    }
  }

  .author-bio {
    p {
      margin-bottom: 1rem;
    }
  }
}

.about-this-site {
  text-align: center;
  @media (min-width: 700px) {
    margin: 5rem 0;
  }

  h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  ul {
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media (min-width: 700px) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  li {
    list-style-type: none;
    margin: 1rem 0;
    font-size: 1.2rem;

    @media (min-width: 700px) {
      display: inline;
      margin: 1rem;
    }
  }
}

.libraries-used {
  display: flex;
  justify-content: space-evenly;
  font-family: 'Roboto', sans-serif;

  @media (min-width: 700px) {
    justify-content: center;
  }
}

.about {
  padding-top: 5rem;
}
