.services {
  @media (min-width: 700px) {
    padding-top: 5rem;
  }

  h4 {
    margin: 1rem 0;
    text-align: center;

    @media (min-width: 700px) {
      font-size: 3rem;
      padding-top: 8rem;
    }
  }
}

.service-container {
  display: flex;
  text-align: center;
}

.service-card {
  @media (min-width: 700px) {
    flex: 1;
    padding: 10rem 4rem;
  }

  img {
    padding: 0.5rem;
    max-height: 80px;
  }

  h3 {
    margin-bottom: 0.5rem;
  }
}
