#portfolio {
  @media (min-width: 700px) {
    padding: 2rem 5rem;
    margin-bottom: 4rem;
  }
  h1 {
    text-align: center;

    @media (min-width: 700px) {
      margin-bottom: 3rem;
    }
  }
}
