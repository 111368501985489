html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
  a {
    text-decoration: none;
    color: black;
  }
}

body {
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(255, 255, 255);
}

img {
  max-width: 100%;
  width: auto;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1rem;
  @media (min-width: 700px) {
    padding: 0 3rem;
  }
}
