nav.main-nav {
  display: flex;
  justify-content: space-around;
  padding-top: 1rem;
  padding-bottom: 1rem;
  align-items: center;
  flex-direction: column;

  @media (min-width: 700px) {
    flex-direction: row;
    justify-content: space-between;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  ul {
    display: flex;
    justify-content: space-evenly;
    list-style: none;
    width: 100%;
    max-width: 699px;

    @media (min-width: 700px) {
      justify-content: space-between;
      padding-left: 3rem;
    }
  }

  li {
    padding: 1rem;
    position: relative;
    font-family: 'Roboto', sans-serif;
  }

  #logo {
    font-size: 2.5rem;
    font-family: 'Sacramento', cursive;
    text-align: center;
    white-space: nowrap;

    @media (min-width: 700px) {
      margin-right: auto;
    }
  }

  a {
    color: black;
    text-decoration: none;
  }

  .current-page {
    color: #4ca1af;
  }
}
