footer.page-footer {
  padding: 2rem 0 3rem;
  font-family: "Roboto", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    color: black;
    text-decoration: none;

    &:hover {
      color: #4ca1af;
    }
  }

  h1 {
    font-size: 2.5rem;
    font-family: "Sacramento", cursive;
    @media (min-width: 700px) {
      margin-bottom: 1rem;
    }
  }

  ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
  }

  li {
    padding: 1rem;
    text-align: center;
    @media (min-width: 700px) {
      padding: 2rem;
    }

    div {
      @media (min-width: 700px) {
        display: inline;
      }
    }
  }
}
