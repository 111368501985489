button {
  background: linear-gradient( 45deg, #4ca1af, #06acc2);
  padding: .5rem 1.5rem;
  border: none;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-size: 1.1rem;
  letter-spacing: 0.08rem;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);

  a {
    text-decoration: none;
    color: white;
  }

  &:hover {
    transition: all 0.5s ease;
    background: #37b95e;
  }
}

.banner {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 3rem;
}

.tag-line {
  text-align: center;
  align-self: center;

  h2 {
    font-size: 4rem;
    font-weight: lighter;
  }

  p {
    padding-top: 1.9rem;
    padding-bottom: 2.5rem;
    font-size: 1.4rem;

    span {
      font-weight: 700;
      color: #4ca1af;
    }
  }
}
