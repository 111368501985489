.projects {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-column-gap: 3rem;
  grid-row-gap: 5rem;
  margin-top: 2rem;

  .project {
    overflow: hidden;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.2);
    text-align: center;
    border-radius: 0.5rem;
  }
  a {
    text-decoration: none;
    color: black;
  }
  p {
    color: #666666;
    font-size: 0.8rem;
  }
}
